// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_history = "history-module__container_history___QprtM";
export var container_history__container_sections = "history-module__container_history__container_sections___x4xkS";
export var container_history__container_sections__content_card = "history-module__container_history__container_sections__content_card___aqzEU";
export var container_history__container_sections__content_card__card_green = "history-module__container_history__container_sections__content_card__card_green___oEKfM";
export var container_history__container_sections__content_card__card_green_2 = "history-module__container_history__container_sections__content_card__card_green_2___UjdeX";
export var container_history__container_sections__content_card__container_experience = "history-module__container_history__container_sections__content_card__container_experience___5DNu7";
export var container_history__container_sections__content_card__container_experience__content_experience = "history-module__container_history__container_sections__content_card__container_experience__content_experience___yPxQH";
export var container_history__container_sections__content_card__container_experience__content_experience__img = "history-module__container_history__container_sections__content_card__container_experience__content_experience__img___EXoeJ";
export var container_history__container_sections__content_card__container_experience__content_text = "history-module__container_history__container_sections__content_card__container_experience__content_text___wXDcP";
export var container_history__container_sections__content_card__container_experience__small = "history-module__container_history__container_sections__content_card__container_experience__small___bwkV2";
export var container_history__container_sections__content_card__container_experience__text = "history-module__container_history__container_sections__content_card__container_experience__text___BgroO";
export var container_history__container_sections__content_card__section = "history-module__container_history__container_sections__content_card__section___tbNtU";
export var container_history__container_sections__content_card__section__card_subtitle = "history-module__container_history__container_sections__content_card__section__card_subtitle___TYKad";
export var container_history__container_sections__content_card__section__card_title = "history-module__container_history__container_sections__content_card__section__card_title___6pUoI";
export var container_history__content_card_img = "history-module__container_history__content_card_img___c8M8p";
export var container_history__content_highlight_1 = "history-module__container_history__content_highlight_1___TdOtU";
export var container_history__content_highlight_2 = "history-module__container_history__content_highlight_2___CjZXG";
export var container_history__img_1 = "history-module__container_history__img_1___N53lJ";
export var container_history__subtitle = "history-module__container_history__subtitle___E2GEP";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";